import { IsTrappingStatus } from '@maru44/huntre-utils/src/models/device'
import { DeviceMessage, DeviceMessageKind } from '@maru44/huntre-utils/src/models/deviceMessage'
import { Box, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { IoTrashSharp } from 'react-icons/io5'
import { useParams } from 'react-router-dom'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { StatusChip } from 'src/components/atoms/StatusChip'
import { DeleteClientDeviceDialog } from 'src/components/dialogs/device/DeleteClientDeviceDialog'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { GlobalProductStateContext } from 'src/components/providers/GlobalProductProvider'
import { useClientDevice } from 'src/hooks/clientDevice/useClientDevice'
import { useClientDeviceMemos } from 'src/hooks/clientDeviceMemo/useClientDeviceMemos'
import { useSim } from 'src/hooks/sim/useSim'
import { ClientDeviceDetail } from 'src/pages/ClientDevice/ClientDeviceDetail'
import { formatTimestamp } from 'src/utils/firebase'
import { capture } from 'src/utils/sentry'
import useSWR from 'swr'
import { ErrorPage } from '../ErrorPage'
import { Memos } from './Memos'
import { DevicePositionMap } from './Position'

export const ClientDevice = () => {
  const { id } = useParams()
  const { client, clientMembers } = useContext(ClientContext)
  const { simPlans } = useContext(GlobalProductStateContext)
  const { getClientDeviceWithDevice, listMessagesForClient } = useClientDevice(id ?? '')
  const { getSim } = useSim()
  const { listDeviceMemos } = useClientDeviceMemos(client!.id, id ?? '')

  const { data, mutate, error } = useSWR(`clients/${client!.id}/clientDevices/${id}`, () => getClientDeviceWithDevice(client!.id))

  const simRes = useSWR([`sims/${data?.device.simId}`, data?.device.simId], data?.device.simId ? () => getSim(data.device.simId!) : null)

  const deviceMessages = useSWR(
    [`devices/${id}/deviceMessages`, data?.clientDevice.createdAt],
    data?.clientDevice.createdAt ? () => listMessagesForClient(data.clientDevice.createdAt) : null,
    {
      onError: (e) => {
        capture(e)
        toast.error('メッセージの取得に失敗しました')
      },
    }
  )

  const memos = useSWR(`clientDevices/${id}/deviceMemos`, () => listDeviceMemos())

  const { deviceTypes, isLoading: isLoadingDeviceType } = useContext(GlobalProductStateContext)
  const { deviceType } = useMemo(() => {
    return {
      deviceType: deviceTypes?.find((v) => v.id === data?.device.type),
    }
  }, [deviceTypes, id, data?.device])

  const [openDelete, setOpenDelete] = useState(false)

  useEffect(() => {
    deviceMessages.mutate()
  }, [deviceMessages.mutate, data?.clientDevice.createdAt])

  return (
    <>
      <Box>
        <Breads breadIds={['home', 'device']} />
        <Stack spacing={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">デバイス詳細</Typography>
            <IconButton onClick={() => setOpenDelete(true)}>
              <IoTrashSharp />
            </IconButton>
          </Box>
          {!isLoadingDeviceType && data && !simRes.isLoading && (
            <ClientDeviceDetail
              clientId={client?.id ?? ''}
              data={data}
              sim={simRes.data ?? null}
              simPlans={simPlans}
              deviceType={deviceType}
              mutate={mutate}
              mutateSim={simRes.mutate}
            />
          )}
          {error && <ErrorPage exception={error} />}
        </Stack>
        {!isLoadingDeviceType && data && !simRes.isLoading && (
          <Stack spacing={2} mt={8}>
            <Typography variant="h6">メモ</Typography>
            <Memos clientId={client?.id ?? ''} deviceId={id ?? ''} members={clientMembers ?? []} memos={memos.data} mutateMemos={memos.mutate} />
          </Stack>
        )}
        {data?.device.position && (
          <Stack spacing={2} mt={8}>
            <Typography variant="h6">位置情報</Typography>
            {data.device.positionUpdatedAt && <Typography>取得日時: {formatTimestamp(data.device.positionUpdatedAt)}</Typography>}
            <DevicePositionMap position={data.device.position} deviceName={data.device.id} />
          </Stack>
        )}
        {!isLoadingDeviceType && data && (
          <Stack spacing={2} mt={8}>
            <Typography variant="h6">通信履歴</Typography>
            {deviceMessages.data && <MessageList data={deviceMessages.data} />}
          </Stack>
        )}
      </Box>
      {!isLoadingDeviceType && data && (
        <DeleteClientDeviceDialog clientId={client!.id} clientDevice={data.clientDevice} open={openDelete} handleClose={() => setOpenDelete(false)} />
      )}
    </>
  )
}

type MessageListProps = {
  data: DeviceMessage[]
}

const MessageList = ({ data }: MessageListProps) => {
  return (
    <TableContainer component={Paper} elevation={6}>
      <Table sx={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">種別</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">作動状況</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">通信日時</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((v) => (
            <MessageRow key={v.id} data={v} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type MessageRowProps = {
  data: DeviceMessage
}

const MessageRow = ({ data }: MessageRowProps) => {
  const status = trappingStatus(data.kind, data.isTrapping)
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {messageKindText(data.kind, data.wakeupCause)}
      </TableCell>
      <TableCell>{status && <StatusChip status={status} />}</TableCell>
      <TableCell>
        <Typography>{formatTimestamp(data.createdAt)}</Typography>
      </TableCell>
    </TableRow>
  )
}

const messageKindText = (kind: DeviceMessageKind, cause: string) => {
  switch (kind) {
    case DeviceMessageKind.SETUP:
      return '電源on'
    case DeviceMessageKind.TRAPPING_STATUS:
      if (cause === 'ESP_SLEEP_WAKEUP_EXT0') {
        return 'トリガー (磁石)'
      }
      return '定期通信'
    case DeviceMessageKind.MEASURE_VOLTAGE:
      return ''
  }
}

const trappingStatus = (kind: DeviceMessageKind, isTrapping: boolean | undefined): IsTrappingStatus | undefined => {
  if (kind === DeviceMessageKind.TRAPPING_STATUS) {
    if (isTrapping) {
      return IsTrappingStatus.Trapping
    }
    return IsTrappingStatus.NotTrapping
  }
}
