import { GoogleAuthProvider, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink, signInWithPopup, signOut } from 'firebase/auth'
import { useCallback } from 'react'
import { baseURL } from 'src/config'
import { firebaseAuth } from 'src/utils/firebase'

// class AuthError {
//   code: string
//   email: string
//   credential: AuthCredential

//   constructor(code: string, email: string, credential: AuthCredential) {
//     this.code = code
//     this.email = email
//     this.credential = credential
//   }
// }

export const useAuth = () => {
  const googleLogin = useCallback(async () => {
    const provider = new GoogleAuthProvider()
    // try {
    //   return await signInWithPopup(firebaseAuth, provider)
    // } catch (e) {
    //   if (e instanceof AuthError) {
    //     if (e.code === 'auth/account-exists-with-different-credential') {
    //       const pendingCred = e.credential
    //       const email = e.email

    //       const methods = await fetchSignInMethodsForEmail(firebaseAuth, email)
    //       if (methods[0] === 'password') {
    //       }
    //       if (methods[0] === 'emailLink') {
    //         // Preserve the pending Facebook credential.
    //         window.localStorage.setItem('pendingCred', JSON.stringify(pendingCred.toJSON()))
    //         // Send email.
    //         await sendSignInLinkToEmail(firebaseAuth, email, {
    //           url: `${baseURL}/auth/complete`,
    //           handleCodeInApp: true,
    //         })
    //       }

    //       // await signInWithPopup(firebaseAuth, provider).then(async (result) => {
    //       //   await linkWithCredential(result.user, pendingCred)
    //       // })
    //     }
    //   }
    // }

    return await signInWithPopup(firebaseAuth, provider)
  }, [])

  const logout = useCallback(() => {
    return signOut(firebaseAuth)
  }, [])

  const sendAuthEmail = useCallback(async (email: string) => {
    await sendSignInLinkToEmail(firebaseAuth, email, {
      url: `${baseURL}/auth/complete`,
      handleCodeInApp: true,
    })
    window.localStorage.setItem('emailForAuth', email)
  }, [])

  const verifyEmailLink = useCallback(async () => {
    const continueUrl = window.location.href
    if (!isSignInWithEmailLink(firebaseAuth, continueUrl)) {
      return
    }
    const emailInLocalStorage = window.localStorage.getItem('emailForAuth')
    if (!emailInLocalStorage) {
      return
    }
    const res = await signInWithEmailLink(firebaseAuth, emailInLocalStorage, continueUrl)
    return res
  }, [])

  return {
    googleLogin,
    logout,
    sendAuthEmail,
    verifyEmailLink,
  }
}
