import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getToken, initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore, Timestamp } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { getStorage as _getStorage } from 'firebase/storage'
import { appCheckSecret, hostName, isDevelopment } from 'src/config'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}
// export const app = isDevelopment ? getApp() : initializeApp(firebaseConfig)
const app = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(app)
export const firestore = getFirestore(app)
export const analytics = getAnalytics(app)
export const getStorage = (bucket?: string) => _getStorage(app ?? bucket)
const functions = getFunctions(app, 'asia-northeast1')

if (isDevelopment) {
  connectAuthEmulator(firebaseAuth, `http://${hostName}:9099`)
  connectFirestoreEmulator(firestore, hostName, 8081)
}
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(appCheckSecret),
  isTokenAutoRefreshEnabled: true,
})
getToken(appCheck)

firebaseAuth.onAuthStateChanged(async (user) => {
  if (!user) return

  try {
    const fn = httpsCallable<
      {},
      {
        next?: string
        code?: string
        httpErrorCode?: {
          statusCode: number
        }
      }
    >(functions, 'handleSignIn', {})
    const res = await fn.call({})

    if (res.data?.next) {
      // if (window.location.pathname !== `/${res.data.next}`) {
      //   window.location.href = `/${res.data.next}`
      // }
      // window.location.href = `/${res.data.next}`
    } else {
      if (res.data?.code === 'not-found') {
        toast.error('利用申請や招待が存在しません', {
          // 例外的に右上に表示
          position: 'top-right',
        })
      } else {
        toast.error('ログインに失敗しました')
      }
      await firebaseAuth.signOut()
      // window.location.href = '/lp'
    }
  } catch (e) {
    toast.error('ログインに失敗しました')
    await firebaseAuth.signOut()
  }
})

export const formatTimestamp = (t: Timestamp) => {
  return t.toDate().toLocaleString('ja-JP', { hourCycle: 'h24', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

export const formatTimestampToDate = (t: Timestamp) => {
  return t.toDate().toLocaleDateString('ja-JP')
}

export const formatTime = (t: Date) => {
  return t.toLocaleString('ja-JP', { hourCycle: 'h24', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

export const formatTimeToDate = (t: Date) => {
  return t.toLocaleDateString('ja-JP')
}

import { getFunctions } from 'firebase/functions'
import { useHttpsCallable as _useHttpsCallable } from 'react-firebase-hooks/functions'
import { toast } from 'react-hot-toast'

type CallableFunction = 'createInvoice' | 'cancelSimSubscriptionUpdate' | 'handleSignIn' | 'createClientApplication' | 'updateLineProfile'
export const useHttpsCallable = <RequestData = unknown, ResponseData = unknown>(functionName: CallableFunction) =>
  _useHttpsCallable<RequestData, ResponseData>(getFunctions(app, 'asia-northeast1'), functionName)
